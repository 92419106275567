body {
  background-color: #282c34;
}
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 800px; /* Ограничение максимальной ширины контента */
  margin: 0 auto; /* Центрирование контента по горизонтали */
}

/* Стили для заголовков */
h1, h2, p {
  font-size: 1.2rem; /* Уменьшаем размер текста */
}

/* Стили для списков */
ul {
  text-align: left;
  padding-left: 20px;
}

/* Иконки */
.icon {
  vertical-align: middle;
  margin-right: 5px;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.bio {
  margin-bottom: 20px;
}

.links {
  margin-top: 20px;
}

.links ul {
  list-style: none;
  padding: 0;
}

.links ul li {
  display: inline;
  margin-right: 10px;
}

.links ul li a {
  color: #61dafb;
  text-decoration: none;
}

.links ul li a:hover {
  text-decoration: underline;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
